<template>
  <form>
    <div class="form__group--three">
      <label for="signature" class="identity"> Signature </label>
      <input
        type="file"
        id="signature"
        name="signatureUrl"
        placeholder="Signature"
        class="identity_input"
        @change="onSignatureChange"
      />
    </div>
  </form>
</template>

<script>
// import api from "@/api/api.js"
export default {
  signatureFileName: null,
  data() {
    return { form: { signatureUrl: "" } };
  },
  methods: {
    onSignatureChange(event) {
      const docs = event.target.files[0];
      this.signatureFileName = docs.name;
      this.$store.commit("fileNameForSignature", this.signatureFileName);
      const reader = new FileReader();
      reader.readAsDataURL(docs);
      reader.onload = (event) => {
        this.form.signatureUrl = event.target.result;
        this.$store.commit("urlForSignature", this.form.signatureUrl);
      };
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/_form.scss";

.identity {
  font-family: "Red Hat Display";
  font-size: 1.2em;
  color: black;
}
.identity_input {
  width: 100%;
  background: #fbfbfb;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  padding: 1.4rem 2rem;
  outline: none;
  font-family: Red Hat Display;
  font-style: normal;
  font-weight: normal;
  font-size: 1.8rem;
  line-height: 2.4rem;
  letter-spacing: 0.05em;
  margin-top: 0.5em;
}
</style>
