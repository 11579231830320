<template>
  <section class="transfer">
    <form
      class="form"
      novalidate="true"
      autocomplete="off"
      @submit.prevent="generateAccountNumber"
    >
      <section class="transfer" v-if="currentStep == 1">
        <h4 class="account-creation">
          {{ this.$store.state.productData.productData.productName }}
        </h4>

        <div class="form__group--three">
          <label for="firstName" class="form__label">
            <input
              readonly
              type="text"
              id="firstName"
              name="firstName"
              placeholder="First Name"
              class="form__input"
              autocorrect="off"
              spellcheck="false"
              autocomplete="off"
              @focus="showBVN = true"
              v-model.lazy="$v.form.firstName.$model"
              :class="{
                'is-invalid': submitted && $v.form.firstName.$error,
              }"
          /></label>
          <small class="error" v-if="submitted && !$v.form.firstName.required"
            >First Name is required</small
          >
        </div>

        <div class="form__group--three">
          <label for="lastName" class="form__label">
            <input
              readonly
              type="text"
              id="lastName"
              name="bvn"
              placeholder="Last Name"
              class="form__input"
              autocorrect="off"
              spellcheck="false"
              autocomplete="off"
              @focus="showBVN = true"
              v-model.lazy="$v.form.lastName.$model"
              :class="{
                'is-invalid': submitted && $v.form.lastName.$error,
              }"
          /></label>
          <small class="error" v-if="submitted && !$v.form.lastName.required"
            >Last Name is required</small
          >
        </div>

        <div class="form__group--three">
          <label for="phoneNumber" class="form__label">
            <input
              type="tel"
              id="phoneNumber"
              name="phoneNumber"
              placeholder="Phone Number"
              class="form__input"
              autocorrect="off"
              spellcheck="false"
              autocomplete="off"
              @focus="showBVN = true"
              v-model.lazy="$v.form.phoneNumber.$model"
              :class="{
                'is-invalid': submitted && $v.form.phoneNumber.$error,
              }"
          /></label>

          <small class="error" v-if="submitted && !$v.form.phoneNumber.numeric"
            >Only digits allowed</small
          >
          <small class="error" v-if="submitted && !$v.form.phoneNumber.required"
            >Phone Number is required</small
          >
        </div>
        <div class="form__group--three">
          <label for="email">
            <input
              id="email"
              type="email"
              name="email"
              placeholder="Enter email address"
              class="form__input"
              v-model="$v.form.email.$model"
              :class="{
                'is-invalid': submitted && $v.form.email.$error,
              }" /></label
          ><small class="error" v-if="submitted && !$v.form.email.required"
            >Email is required</small
          >
          <br />
          <small class="error" v-if="submitted && !$v.form.email.email"
            >Needs to be a valid email.</small
          >
        </div>
        <div class="form__group--three">
          <label for="address" class="form__label">
            <input
              type="text"
              id="address"
              name="address"
              placeholder="Residential Address"
              class="form__input"
              autocorrect="off"
              spellcheck="false"
              autocomplete="off"
              @focus="showBVN = true"
              v-model.lazy="$v.form.address.$model"
              :class="{
                'is-invalid': submitted && $v.form.address.$error,
              }"
          /></label>
          <small class="error" v-if="submitted && !$v.form.address.required"
            >Residential Address is required</small
          >
        </div>
      </section>
      <section v-if="currentStep === 2">
        <div class="form__group--three">
          <label for="otp" class="form__label">
            <input
              type="tel"
              id="otp"
              name="otp"
              placeholder="Enter OTP received"
              class="form__input"
              autocorrect="off"
              spellcheck="false"
              autocomplete="off"
              @focus="showBVN = true"
              v-model.lazy="$v.form.otp.$model"
              :class="{
                'is-invalid': submitted && $v.form.otp.$error,
              }"
          /></label>
          <small class="error" v-if="submitted && !$v.form.otp.required"
            >Otp is required</small
          >
          <small class="error" v-if="submitted && !$v.form.otp.numeric"
            >Only digits allowed</small
          >
        </div>
      </section>
      <section v-if="currentStep === 3">
        <div class="form__group--three">
          <label for="idCard" class="identity"> ID Card </label>
          <input
            ref="idCard"
            type="file"
            id="idCard"
            name="idCardURL"
            placeholder="ID Card"
            class="identity_input"
            @change="onIDCardChange"
          />
        </div>
      </section>
      <section v-if="currentStep === 4">
        <passportUpload />
      </section>
      <section v-if="currentStep === 5"><signatureUpload /></section>
      <section v-if="currentStep === 6">
        <h3 class="validation">You've done a good job validating your details 👍</h3>
        <p class="acct">
          You are one click away from generating your
          {{ this.$store.state.productData.productData.productName }} account details
        </p>
      </section>
      <button
        type="submit"
        class="otp_btn"
        @click.prevent="nextStep"
        v-if="currentStep === 1"
      >
        <loading v-if="loading" />
        <span>Send OTP</span>
      </button>
      <button
        v-if="currentStep === 2"
        @click.prevent="nextStep"
        class="btn btn__red btn__group"
      >
        <loading v-if="loading" />
        <span>Validate OTP</span>
      </button>
      <button
        v-if="currentStep === 3"
        @click.prevent="nextStep"
        class="btn btn__red btn__group"
      >
        <loading v-if="loading" />
        <span>Upload ID</span>
      </button>
      <button
        v-if="currentStep === 4"
        @click.prevent="nextStep"
        class="btn btn__red btn__group"
      >
        <loading v-if="loading" />
        <span>Upload Passport Photo</span>
      </button>
      <button
        v-if="currentStep === 5"
        @click.prevent="nextStep"
        class="btn btn__red btn__group"
      >
        <loading v-if="loading" />
        <span>Upload Signature</span>
      </button>
      <button
        v-if="currentStep === 6"
        @click.prevent="generateAccountNumber"
        class="btn btn__red btn__group"
      >
        <loading v-if="loading" />
        <span>Complete Upgrade</span>
      </button>
    </form>
  </section>
</template>

<script>
import { required, numeric, email } from "vuelidate/lib/validators";
import { mapState } from "vuex";
import api from "@/api/api.js";
import dayjs from "dayjs";
import PassportUpload from "./PassportUpload.vue";
import SignatureUpload from "./SignatureUpload.vue";
let utc = require("dayjs/plugin/utc");
import config from "../../config/index";
dayjs.extend(utc);
export default {
  data() {
    return {
      newAccount: "",
      currentStep: 1,
      submitted: false,
      loading: false,
      showOtpInput: false,
      idFileName: null,
      passportPhotoFileName: this.$store.state.passportName,
      signatureFileName: null,
      meansOfIDBase64: null,
      photoIDBase64: null,
      signatureBase64: null,
      form: {
        firstName: this.$store.state.userBVNData.userBVN.FirstName,
        lastName: this.$store.state.userBVNData.userBVN.LastName,
        phoneNumber: this.$store.state.userBVNData.userBVN.PhoneNumber,
        otp: "",
        address: "",
        email: this.$store.state.userBVNData.userBVN.Email,
        idCardURL: "",
        passportPhotoUrl: this.$store.state.passportUrl,
      },
    };
  },
  validations: {
    form: {
      firstName: {
        required,
      },
      lastName: { required },
      phoneNumber: { numeric, required },
      otp: { numeric, required },
      address: { required },
      email: { required, email },
    },
  },
  computed: mapState(["user"]),

  components: {
    passportUpload: PassportUpload,
    signatureUpload: SignatureUpload,
  },
  methods: {
    onIDCardChange(event) {
      const doc = event.target.files[0];
      this.idFileName = doc.name;
      const reader = new FileReader();
      reader.readAsDataURL(doc);
      reader.onload = (event) => {
        this.form.idCardURL = event.target.result;
      };
    },
    uploadIdWithResponse() {
      let payload = {
        appId: "",
        folderName: this.idFileName,
        fileName: this.idFileName,
        base64String: this.form.idCardURL,
      };
      this.loading = true;
      api
        .uploadImageToAzure(payload)
        .then((response) => {
          this.loading = false;
          this.meansOfIDBase64 = response.data.url;
          this.currentStep++;
          this.$message({
            showClose: true,
            message: `Means of ID uploaded successfully.`,
            type: "success",
            duration: 10000,
          });
        })
        .catch((error) => {
          this.loading = false;
          this.loading = false;
          this.$message({
            showClose: true,
            message: `Error uploading means of ID`,
            type: "error",
            duration: 10000,
          });
          this.$store.state.passportName === null;
          throw error;
        });
    },
    uploadpassportPhotoWithResponse() {
      let payload = {
        appId: "",
        folderName: this.$store.state.passportName,
        fileName: this.$store.state.passportName,
        base64String: this.$store.state.passportUrl,
      };
      this.loading = true;
      api
        .uploadImageToAzure(payload)
        .then((response) => {
          this.loading = false;
          this.photoIDBase64 = response.data.url;
          this.$message({
            showClose: true,
            message: `Photo uploaded successfully.`,
            type: "success",
            duration: 10000,
          });
          this.currentStep++;
        })
        .catch((error) => {
          this.loading = false;
          this.$message({
            showClose: true,
            message: `Error uploading passport photo`,
            type: "error",
            duration: 10000,
          });
          throw error;
        });
    },
    uploadSignatureWithResponse() {
      let payload = {
        appId: "",
        folderName: this.$store.state.signatureName,
        fileName: this.$store.state.signatureName,
        base64String: this.$store.state.signatureUrl,
      };
      this.loading = true;
      api
        .uploadImageToAzure(payload)
        .then((response) => {
          this.loading = false;
          this.signatureBase64 = response.data.url;
          this.$message({
            showClose: true,
            message: `Signature uploaded successfully.`,
            type: "success",
            duration: 10000,
          });
          this.currentStep++;
        })
        .catch((error) => {
          this.loading = false;
          this.$message({
            showClose: true,
            message: `Error uploading signature`,
            type: "error",
            duration: 10000,
          });
          throw error;
        });
    },
    async generateAccountNumber() {
      this.submitted = true;
      const formattedDOB = dayjs(
        String(this.$store.state.userBVNData.userBVN.DateOfBirth)
      )
        .utc()
        .local()
        .format();
      let payload = {
        accountDetails: {
          bvn: this.$store.state.inputBVN,
          dateOfBirth: formattedDOB,
          email: this.form.email,
          preferredName: this.form.firstName,
          phoneNumber: this.form.phoneNumber,
          firstName: this.form.firstName,
          lastName: this.form.lastName,
          productCode: this.$store.state.productData.productData.productCode,
          branch: "NG0020002",
          currency: this.$store.state.productData.productData.currency,
          address: this.form.address,
          sector: "4200",
          industry: "4202",
          nationality: "NG",
          gender: this.user.walletInfo.gender,
          initialAccountSelected: this.$store.state.productData.productData.productCode,
          clientID: config.otpClientId,
          childFirstName: "",
          childMiddleName: "",
          childLastName: "",
          childDateOfBirth: "",
          childGender: "",
          referralCode: this.user.profileInfo.referralcode,
          requestRefId1: "",
          requestRefId2: "",
          countryOfBirth: "",
          otherNationality: "",
          taxId: "",
          landMark: "",
          stateOfResidence: "",
          dao: null,
        },
        documents: {
          accountNumber: "",
          folio: "",
          source: "",
          meansOfIDBase64: this.meansOfIDBase64,
          otherBase64: this.photoIDBase64,
          photoIDBase64: this.photoIDBase64,
          addState: "",
          urlReference1Base64: "",
          urlReference2Base64: "",
          photoMandateBase64: this.photoIDBase64,
          signatureMandateBase64: this.signatureBase64,
          idno: "a",
          idtype: "a",
          issuedate: "",
          iexpirydate: "",
          stateOfResidence: "a",
          landMark: "a",
          nearestBusStop: "a",
          bvnImageURL: this.photoIDBase64,
        },
      };
      this.loading = true;
      api
        .accountNumberGenerator(payload)
        .then((response) => {
          this.loading = false;
          let newAccount = response.data.accountDetails[0].accountNumber;
          if (newAccount) {
            this.newAccount = newAccount;
            this.updateWalletToRegularProfile();
          } else if (response.data.responseDescription) {
            this.$message({
              showClose: true,
              message: `${response.data.responseDescription}`,
              type: "warning",
              duration: 10000,
            });
            setTimeout(() => {
              this.$router.push("/dashboard-home");
            }, 1000);
          }
        })
        .catch((error) => {
          this.loading = false;
          setTimeout(() => {
            this.$router.push("/dashboard-home");
          }, 1000);

          this.$message({
            showClose: true,
            message: `Error generating account number, try again later!`,
            type: "error",
            duration: 10000,
          });
          throw error;
        });
    },
    nextStep() {
      this.submitted = true;
      this.$v.$touch();
      if (this.currentStep === 1) {
        if (
          this.$v.form.firstName.$invalid ||
          this.$v.form.lastName.$invalid ||
          this.$v.form.phoneNumber.$invalid ||
          this.$v.form.email.$invalid ||
          this.$v.form.address.$invalid
        ) {
          return;
        } else {
          this.generateOnboardingOtp();
        }
      } else if (this.currentStep === 2) {
        if (this.$v.form.otp.$invalid) {
          return;
        } else {
          this.validateOtp();
        }
      } else if (this.currentStep === 3) {
        if (this.idFileName === null) {
          return;
        } else {
          this.uploadIdWithResponse();
        }
      } else if (this.currentStep === 4) {
        this.uploadpassportPhotoWithResponse();
      } else if (this.currentStep === 5) {
        this.uploadSignatureWithResponse();
      }
    },
    validateOtp() {
      this.loading = true;
      let payload = { mobile: this.form.phoneNumber, otp: this.form.otp };
      api
        .validateOtp(payload)
        .then((response) => {
          this.loading = false;
          let responseStatus = response.status;
          if (responseStatus === 200) {
            this.$message({
              showClose: true,
              message: `OTP Validated Successfully`,
              type: "success",
              duration: 10000,
            });
            this.currentStep++;
          }
        })
        .catch((error) => {
          this.loading = false;
          this.$message({
            showClose: true,
            message: `OTP validation error`,
            type: "error",
            duration: 10000,
          });
          throw error;
        });
    },
    generateOnboardingOtp() {
      this.loading = true;
      let payload = {
        mobile: this.form.phoneNumber,
        email: this.form.email,
        clientID: config.otpClientId,
      };
      api
        .generateOtp(payload)
        .then((response) => {
          this.loading = false;
          let responseStatus = response.status;
          if (responseStatus === 200) {
            this.$message({
              showClose: true,
              message: `OTP Generated Successfully`,
              type: "success",
              duration: 10000,
            });
            this.currentStep++;
          }
        })
        .catch((error) => {
          this.loading = false;
          this.$message({
            showClose: true,
            message: `OTP generation error`,
            type: "error",
            duration: 10000,
          });
          throw error;
        });
    },

    updateWalletToRegularProfile() {
      this.loading = true;
      const formattedDOB = dayjs(
        String(this.$store.state.userBVNData.userBVN.DateOfBirth)
      ).format("DD-MM-YYYY");

      let payload = {
        bvn: this.$store.state.inputBVN,
        dateOfBirth: formattedDOB,
        smId: this.user.profileInfo.SMID,
        pryaccount: this.newAccount,
        // customerid: this.user.profileInfo.customerId,
        customerid: Number(489992),
      };
      // console.log("payload", payload);
      api
        .updateWalletToRegularProfile(payload)
        .then((response) => {
          // console.log(response, "response");

          setTimeout(() => {
            this.$router.push("/dashboard-home");
          }, 1000);

          this.loading = false;
          let responseStatus = response.Status;
          let responseMessage = response.Message;

          if (responseStatus) {
            let accountsPayload = {
              profileType: "",
              BVN: this.$store.state.inputBVN,
            };
            this.$store.commit("setAccountsPayload", accountsPayload);
            this.$store.dispatch("handleGetUserAccounts");

            this.$message({
              showClose: true,
              message: `Welcome to your World of Convenient Banking! ${this.newAccount} is your new account number`,
              type: "success",
              duration: 10000,
            });
          } else {
            this.$message({
              showClose: true,
              message: `${
                responseMessage.charAt(0).toUpperCase() + responseMessage.slice(1)
              }`,
              type: "error",
              duration: 10000,
            });
          }
        })
        .catch((error) => {
          this.$message({
            showClose: true,
            message: `${error}`,
            type: "error",
            duration: 10000,
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_form.scss";
.identity {
  font-family: "Red Hat Display";
  font-size: 1.2em;
  color: black;
}
.identity_input {
  width: 100%;
  background: #fbfbfb;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  padding: 1.4rem 2rem;
  outline: none;
  font-family: Red Hat Display;
  font-style: normal;
  font-weight: normal;
  font-size: 1.8rem;
  line-height: 2.4rem;
  letter-spacing: 0.05em;
  margin-top: 0.5em;
}
.acct {
  font-family: "Red Hat Display";
  padding-bottom: 1.2em;
}
.validation {
  font-family: "Red Hat Display";
  padding-bottom: 1.2em;
}
.sources__card--top {
  color: #db353a;
}
.account-feature {
  color: #db353a;
  font-size: 16px;
}
.features-list {
  list-style: none;
}
ul li {
  font-size: 13px;
  padding: 0.2em;
  padding-left: 0;
}
.features-container {
  padding-top: 0.5em;
}
.product-description {
  font-size: 13px;
  padding: 0.2em;
  padding-left: 0;
}
.btn {
  width: 100%;
}
.account-creation {
  font-family: "Red Hat Display";
  padding-bottom: 1.2em;
}
.otp_btn {
  width: 90%;
  margin: 0 auto;
  margin-top: 1.2em;
  background: #db353a;
  color: #ffffff;
  border-radius: 5px;
  outline: none;
  border: none;
  cursor: pointer;
  padding: 1.2rem 3.8rem;
  font-family: Red Hat Display;
  font-style: normal;
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 2.4rem;
  letter-spacing: 0.05em;
  transition: opacity 0.3s;
  display: flex;
  justify-content: center;
  -moz-column-gap: 1.5rem;
  column-gap: 1.5rem;
}
</style>
